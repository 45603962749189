import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDownCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import BulkDeleteLeadsForm from "@/components/Forms/Lead/BulkDeleteLeadsForm";
import UserAssignOrChangeLeadStatus from "./UsersAssignOrChangeLeadStatus";
import { DialogDescription } from "@radix-ui/react-dialog";
import useDialogStore from "@/store/useDialog.store";
const UsersBulkActionHandler = ({
  selectedRecords,
}: {
  selectedRecords: string[];
}) => {
  const { open, handleOpen, handleClose } = useDialogStore();
  const [dialogState, setDialogState] = useState<{
    view: JSX.Element | null;
    title: string;
  }>({ view: null, title: "" });

  if (!selectedRecords.length) return null;

  const handleActionClick = (
    action: "assign" | "export" | "spam" | "delete"
  ) => {
    switch (action) {
      case "assign":
        setDialogState({
          view: (
            <UserAssignOrChangeLeadStatus selectedRecords={selectedRecords} />
          ),
          title: "Assign Leads",
        });
        handleOpen();
        break;
      case "export":
        setDialogState({
          view: <p>Export leads view</p>,
          title: "",
        });
        handleOpen();
        break;
      case "spam":
        console.log("Spam Leads");
        setDialogState({
          view: null,
          title: "",
        });
        handleClose();
        break;
      case "delete":
        setDialogState({
          view: <BulkDeleteLeadsForm selectedRecords={selectedRecords} />,
          title: "",
        });
        handleOpen();
        break;
      default:
        setDialogState({ view: null, title: "" });
        handleClose();
        break;
    }
  };

  return (
    <div className="flex flex-row justify-start items-center gap-4">
      <p className="text-gray-500 text-sm">
        {selectedRecords.length} Records Selected
      </p>
      <div>
        {/* <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}> */}
        <Dialog
          open={open}
          onOpenChange={(isOpen) => (isOpen ? handleOpen() : handleClose())}
        >
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary">
                Lead Actions
                <ChevronDownCircle className="w-4 h-4 ml-2" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DialogTrigger asChild>
                <DropdownMenuItem onClick={() => handleActionClick("assign")}>
                  Assign or Change Status
                </DropdownMenuItem>
              </DialogTrigger>
              <DialogTrigger asChild>
                <DropdownMenuItem onClick={() => handleActionClick("export")}>
                  Export
                </DropdownMenuItem>
              </DialogTrigger>
              <DropdownMenuItem onClick={() => handleActionClick("spam")}>
                Mark as Spam
              </DropdownMenuItem>
              <DialogTrigger asChild>
                <DropdownMenuItem onClick={() => handleActionClick("delete")}>
                  Delete
                </DropdownMenuItem>
              </DialogTrigger>
            </DropdownMenuContent>
          </DropdownMenu>
          {dialogState.view && (
            <DialogContent
              onInteractOutside={(e) => e.preventDefault()}
              onEscapeKeyDown={(e) => e.preventDefault()}
            >
              <DialogHeader>
                <DialogTitle>{dialogState.title ?? ""}</DialogTitle>
                <DialogDescription></DialogDescription>
              </DialogHeader>
              {dialogState.view}
            </DialogContent>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default UsersBulkActionHandler;
