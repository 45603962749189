import { Button } from "@/components/ui/button";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { serverErrorMessage } from "@/lib/utils";
import useDialogStore from "@/store/useDialog.store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

type selectedRecordsProps = {
  selectedRecords: string[];
};

const BulkDeleteLeadsForm = ({ selectedRecords }: selectedRecordsProps) => {
  const { handleClose } = useDialogStore();
  const axiosInstance = useAxiosAuthInstance();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () =>
      await axiosInstance.post("/crm/delete-users", {
        user_ids: selectedRecords,
      }),
    onSuccess: (res) => {
      handleClose();
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const handleSubmit = () => {
    console.log("Form submitted");
    mutation.mutate();
  };

  return (
    <div className="align-center">
      <p className="text-xl mb-6">
        Are you sure you want to delete the selected leads?
      </p>
      <div className="flex flex-row justify-center items-center gap-4">
        <Button className="btn-outline">Cancel</Button>
        <Button className="bg-red-500 hover:bg-red-600" onClick={handleSubmit}>
          Delete
        </Button>
      </div>
    </div>
  );
};

export default BulkDeleteLeadsForm;
