import { create } from "zustand";

type DialogStore = {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
};

const useDialogStore = create<DialogStore>((set) => ({
  open: false,
  handleOpen: () => set({ open: true }),
  handleClose: () => set({ open: false }),
}));

export default useDialogStore;
