export const DESTINATION_COUNTRIES: string[] = [
  "Afghanistan (AF)",
  "Åland Islands (AX)",
  "Albania (AL)",
  "Algeria (DZ)",
  "American Samoa (AS)",
  "Andorra (AD)",
  "Angola (AO)",
  "Anguilla (AI)",
  "Antarctica (AQ)",
  "Antigua & Barbuda (AG)",
  "Argentina (AR)",
  "Armenia (AM)",
  "Aruba (AW)",
  "Australia (AU)",
  "Austria (AT)",
  "Azerbaijan (AZ)",
  "Bahamas (BS)",
  "Bahrain (BH)",
  "Bangladesh (BD)",
  "Barbados (BB)",
  "Belarus (BY)",
  "Belgium (BE)",
  "Belize (BZ)",
  "Benin (BJ)",
  "Bermuda (BM)",
  "Bhutan (BT)",
  "Bolivia (BO)",
  "Bosnia & Herzegovina (BA)",
  "Botswana (BW)",
  "Bouvet Island (BV)",
  "Brazil (BR)",
  "British Indian Ocean Territory (IO)",
  "British Virgin Islands (VG)",
  "Brunei (BN)",
  "Bulgaria (BG)",
  "Burkina Faso (BF)",
  "Burundi (BI)",
  "Cambodia (KH)",
  "Cameroon (CM)",
  "Canada (CA)",
  "Cape Verde (CV)",
  "Caribbean Netherlands (BQ)",
  "Cayman Islands (KY)",
  "Central African Republic (CF)",
  "Chad (TD)",
  "Chile (CL)",
  "China (CN)",
  "Christmas Island (CX)",
  "Cocos (Keeling) Islands (CC)",
  "Colombia (CO)",
  "Comoros (KM)",
  "Congo - Brazzaville (CG)",
  "Congo - Kinshasa (CD)",
  "Cook Islands (CK)",
  "Costa Rica (CR)",
  "Côte d’Ivoire (CI)",
  "Croatia (HR)",
  "Cuba (CU)",
  "Curaçao (CW)",
  "Cyprus (CY)",
  "Czechia (CZ)",
  "Denmark (DK)",
  "Djibouti (DJ)",
  "Dominica (DM)",
  "Dominican Republic (DO)",
  "Ecuador (EC)",
  "Egypt (EG)",
  "El Salvador (SV)",
  "Equatorial Guinea (GQ)",
  "Eritrea (ER)",
  "Estonia (EE)",
  "Eswatini (SZ)",
  "Ethiopia (ET)",
  "Falkland Islands (FK)",
  "Faroe Islands (FO)",
  "Fiji (FJ)",
  "Finland (FI)",
  "France (FR)",
  "French Guiana (GF)",
  "French Polynesia (PF)",
  "French Southern Territories (TF)",
  "Gabon (GA)",
  "Gambia (GM)",
  "Georgia (GE)",
  "Germany (DE)",
  "Ghana (GH)",
  "Gibraltar (GI)",
  "Greece (GR)",
  "Greenland (GL)",
  "Grenada (GD)",
  "Guadeloupe (GP)",
  "Guam (GU)",
  "Guatemala (GT)",
  "Guernsey (GG)",
  "Guinea (GN)",
  "Guinea-Bissau (GW)",
  "Guyana (GY)",
  "Haiti (HT)",
  "Heard & McDonald Islands (HM)",
  "Honduras (HN)",
  "Hong Kong SAR China (HK)",
  "Hungary (HU)",
  "Iceland (IS)",
  "India (IN)",
  "Indonesia (ID)",
  "Iran (IR)",
  "Iraq (IQ)",
  "Ireland (IE)",
  "Isle of Man (IM)",
  "Israel (IL)",
  "Italy (IT)",
  "Jamaica (JM)",
  "Japan (JP)",
  "Jersey (JE)",
  "Jordan (JO)",
  "Kazakhstan (KZ)",
  "Kenya (KE)",
  "Kiribati (KI)",
  "Kuwait (KW)",
  "Kyrgyzstan (KG)",
  "Laos (LA)",
  "Latvia (LV)",
  "Lebanon (LB)",
  "Lesotho (LS)",
  "Liberia (LR)",
  "Libya (LY)",
  "Liechtenstein (LI)",
  "Lithuania (LT)",
  "Luxembourg (LU)",
  "Macao SAR China (MO)",
  "Madagascar (MG)",
  "Malawi (MW)",
  "Malaysia (MY)",
  "Maldives (MV)",
  "Mali (ML)",
  "Malta (MT)",
  "Marshall Islands (MH)",
  "Martinique (MQ)",
  "Mauritania (MR)",
  "Mauritius (MU)",
  "Mayotte (YT)",
  "Mexico (MX)",
  "Micronesia (FM)",
  "Moldova (MD)",
  "Monaco (MC)",
  "Mongolia (MN)",
  "Montenegro (ME)",
  "Montserrat (MS)",
  "Morocco (MA)",
  "Mozambique (MZ)",
  "Myanmar (Burma) (MM)",
  "Namibia (NA)",
  "Nauru (NR)",
  "Nepal (NP)",
  "Netherlands (NL)",
  "New Caledonia (NC)",
  "New Zealand (NZ)",
  "Nicaragua (NI)",
  "Niger (NE)",
  "Nigeria (NG)",
  "Niue (NU)",
  "Norfolk Island (NF)",
  "North Korea (KP)",
  "North Macedonia (MK)",
  "Northern Mariana Islands (MP)",
  "Norway (NO)",
  "Oman (OM)",
  "Pakistan (PK)",
  "Palau (PW)",
  "Palestinian Territories (PS)",
  "Panama (PA)",
  "Papua New Guinea (PG)",
  "Paraguay (PY)",
  "Peru (PE)",
  "Philippines (PH)",
  "Pitcairn Islands (PN)",
  "Poland (PL)",
  "Portugal (PT)",
  "Puerto Rico (PR)",
  "Qatar (QA)",
  "Réunion (RE)",
  "Romania (RO)",
  "Russia (RU)",
  "Rwanda (RW)",
  "Samoa (WS)",
  "San Marino (SM)",
  "São Tomé & Príncipe (ST)",
  "Saudi Arabia (SA)",
  "Senegal (SN)",
  "Serbia (RS)",
  "Seychelles (SC)",
  "Sierra Leone (SL)",
  "Singapore (SG)",
  "Sint Maarten (SX)",
  "Slovakia (SK)",
  "Slovenia (SI)",
  "Solomon Islands (SB)",
  "Somalia (SO)",
  "South Africa (ZA)",
  "South Georgia & South Sandwich Islands (GS)",
  "South Korea (KR)",
  "South Sudan (SS)",
  "Spain (ES)",
  "Sri Lanka (LK)",
  "St. Barthélemy (BL)",
  "St. Helena (SH)",
  "St. Kitts & Nevis (KN)",
  "St. Lucia (LC)",
  "St. Martin (MF)",
  "St. Pierre & Miquelon (PM)",
  "St. Vincent & Grenadines (VC)",
  "Sudan (SD)",
  "Suriname (SR)",
  "Svalbard & Jan Mayen (SJ)",
  "Sweden (SE)",
  "Switzerland (CH)",
  "Syria (SY)",
  "Taiwan (TW)",
  "Tajikistan (TJ)",
  "Tanzania (TZ)",
  "Thailand (TH)",
  "Timor-Leste (TL)",
  "Togo (TG)",
  "Tokelau (TK)",
  "Tonga (TO)",
  "Trinidad & Tobago (TT)",
  "Tunisia (TN)",
  "Turkey (TR)",
  "Turkmenistan (TM)",
  "Turks & Caicos Islands (TC)",
  "Tuvalu (TV)",
  "U.S. Outlying Islands (UM)",
  "U.S. Virgin Islands (VI)",
  "Uganda (UG)",
  "Ukraine (UA)",
  "United Arab Emirates (AE)",
  "United Kingdom (GB)",
  "United States (US)",
  "Uruguay (UY)",
  "Uzbekistan (UZ)",
  "Vanuatu (VU)",
  "Vatican City (VA)",
  "Venezuela (VE)",
  "Vietnam (VN)",
  "Wallis & Futuna (WF)",
  "Western Sahara (EH)",
  "Yemen (YE)",
  "Zambia (ZM)",
  "Zimbabwe (ZW)",
];

export const VISA_TYPES: string[] = [
  "PR Visa",
  "Visit Visa",
  "Work Visa",
  "Education Visa",
  "Travel Visa",
];

export const MARITAL_STATUS: string[] = [
  "Married",
  "Never Married",
  "Widowed",
  "Legally Separated",
  "Annulled Marriage",
  "Divorced",
  "Common Law",
];

export const HIGHEST_EDU_LEVEL: string[] = [
  "Ph.D.",
  "Master's Degree",
  "2 or more Bachelor's Degrees",
  "Bachelor's Degree (4 years)",
  "Bachelor's Degree (3 years)",
  "Bachelor's Degree (2 years)",
  "Bachelor's Degree (1 years)",
  "Diploma, ITI or Apprentice (3 years)",
  "Diploma, ITI or Apprentice (2 years)",
  "Diploma, ITI or Apprentice (1 years)",
  "High School Diploma",
  "Below High School Diploma",
];

export const LEAD_STATUSES: string[] = [
  "New Lead",
  "Not Reachable",
  "Wrong Number",
  "Call Disconnected",
  "Call Not Answering",
  "Closed Lost",
  "Closed Junk",
  "Interested Cold",
  "Interested Warm",
  "Interested Hot",
  "Registered (IER)",
  "Full Paid",
  "Contact in Future",
];

export const LANGUAGE_SKILL = [
  "Pearson Test of English (PTE)",
  "IELTS",
  "French",
  "CELPIP Test",
];

export const LANGUAGE_SKILL_VALUES = [
  1, 2, 3, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9,
].map(String);

export const WORK_EXP_DURATION = [
  "Below 3 Months",
  "3 to 6 Months",
  "6 to 9 Months",
  "9 Months to 1 Year",
  "1 to 2 Years",
  "2 to 3 Years",
  "3 to 4 Years",
  "4 to 5 Years",
  "5 to 6 Years",
];

export const WORK_EXP_JOB_LOCATIONS = ["India", "Outside India"];
export const WORK_EXP_EMP_TYPE = ["Government", "Private", "Others"];

export const RELATIONS = [
  "Mother or Father",
  "Daughter or Son",
  "Sister or Brother",
  "Niece or Nephew",
  "Grandmother or Grandfather",
  "Granddaugher or Grandson",
  "Aunt or Uncle",
  "Spouse or common-law partner",
];

export const RELATIVE_AGE = ["Younger than 18 years", "18 years or over"];
export const RELATIVE_EMP_STATUS = ["Employed", "Self-Employed", "Unemployed"];
export const RELATIVE_FIN_RESPONSIBLE = [0, 1, 2, 3, 4, 5, 6, 7, 8].map(String);

export const WORK_IN_CAN_AUS_YEARS_WORKED = [
  "None",
  "1 Year",
  "2 Years or more",
];

export const WORK_IN_CAN_AUS_EMP_LEFT = [
  "Less than a year ago",
  "More than a year ago",
];
