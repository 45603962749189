import { LeadNotesSchema } from "@/components/FormInterfaces/Assessment";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { LEAD_STATUSES } from "@/data/assessment.data";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import useFormHandler from "@/hooks/useFormHandler";
import { useParams } from "react-router-dom";
import { Textarea } from "@/components/ui/textarea";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import useDialogStore from "@/store/useDialog.store";

const CreateCallNoteForm = () => {
  const { handleClose } = useDialogStore();
  const { userId } = useParams();
  const axiosInstance = useAxiosAuthInstance();
  const form = useForm<z.infer<typeof LeadNotesSchema>>({
    resolver: zodResolver(LeadNotesSchema),
  });

  const { data: leadStatus } = useQuery({
    queryKey: ["leadStatus", userId],
    queryFn: async () => await axiosInstance.get(`/user/${userId}/lead-status`),
  });

  useEffect(() => {
    form.reset({ status: leadStatus?.data });
  }, [leadStatus?.data]);

  const { successMessage, errorResponse, onSubmit } = useFormHandler({
    mode: "CREATE",
    postUrl: `/user/${userId}/calls`,
    formSchema: LeadNotesSchema,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (successMessage) {
      handleClose();
      toast.success(successMessage);
      queryClient.invalidateQueries({
        queryKey: ["userCallNotes", userId],
      });
    }
    if (errorResponse) {
      toast.error(errorResponse?.message);
    }
  }, [successMessage, errorResponse]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 pt-4">
        <FormField
          control={form.control}
          name="note"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Call Notes</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter Call Notes here..."
                  className="resize-none"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="status"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Lead Status</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Status" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {LEAD_STATUSES.map((item, index: number) => {
                    return (
                      <SelectItem value={item} key={index}>
                        {item}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Reminder Title</FormLabel>
              <FormControl>
                <Input placeholder="Reminder Title" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="followup"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Next Followup</FormLabel>
              <FormControl>
                <Input type="datetime-local" {...field} />
              </FormControl>
              <FormDescription>
                Note: Reminder notification sent 2 mins before the shedule time.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex flex-col">
          <Button type="submit" className="bg-primary">
            Submit
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default CreateCallNoteForm;
